import * as yup from 'yup'

const schema = yup
  .object({
    code: yup
      .string()
      .required(
        'The 9-digit Entrant Code you entered was not found. Please re-confirm the Entrant Code.',
      ),
  })
  .required()

export default schema
