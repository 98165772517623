import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { gtmConfig } from './config'
import { useAnalytics } from './hooks/use-analytics'
import theme from './theme'
import './App.css'
import Regisform from './containers/Regisform'
import RegisFirstPage from './containers/RegisFirstPage'
import Thankyou from './containers/Thankyou'
import TermAndCondition from './containers/TermAndCondition'
import Details from './containers/Details'
import {
  Container,
  Box,
  Paper,
  Button,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
  Grid,
} from '@mui/material'
import LogoBrand from './components/LogoBrand'

function App() {
  const urlParams = new URLSearchParams(window.location.search)
  const qrCode = urlParams.get('qrcode')
  const code = urlParams.get('code')
  useAnalytics(gtmConfig)

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          {/* <Route exact path="/" element={<RegisFirstPage qrCode={code} />} />
          <Route path="information" element={<Regisform qrCode={qrCode} />} />
          <Route path="detail" element={<Details qrCode={qrCode} />} />
          <Route path="thankyou" element={<Thankyou qrCode={qrCode} />} />
          <Route path="terms-conditions" element={<TermAndCondition />} /> */}
          <Route path="*" element={<ClosedPage />} />
        </Routes>
      </div>
    </ThemeProvider>
  )
}

const ClosedPage = () => {
  return (
    <Container maxWidth="sm" sx={{ pb: 7 }}>
      <LogoBrand />
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" textAlign={'center'}>
          Wonder Market is now closed
        </Typography>
        <Typography variant="h6" textAlign={'center'}>
          See you in The Fields!
        </Typography>
      </Paper>
    </Container>
  )
}

export default App
