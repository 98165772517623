import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'

const FileName = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '16px',
  borderWidth: '2px',
  borderRadius: '12px',
  borderColor: '#FFF',
  borderStyle: 'solid',
  alignItems: 'center',
  background: '#F0ECE5',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
}))

function calc_image_size(image) {
  let y = 1
  if (image.endsWith('==')) {
    y = 2
  }
  const x_size = image.length * (3 / 4) - y
  return Math.round(x_size / 1024)
}

function Filename({ files, onClick }) {
  return (
    files &&
    files.length > 0 &&
    files.map((file, i) =>
      file.slipUrl ? (
        <Link key={`${file.imgB64Name}-${i}`} href={file.slipUrl} target="_blank">
          <Box mt={1.6}>
            <FileName>
              <Box>
                <img
                  style={{ margin: 'auto' }}
                  src={window.location.origin + '/file_icon.png'}
                  width="32px"
                  height="32px"
                  alt="file_icon"
                />
              </Box>
              <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Typography
                  variant="h4"
                  noWrap
                  sx={{
                    margin: 0,
                    fontSize: {
                      xs: '12px!important',
                      md: '14px!important',
                    },
                    fontWeight: 400,
                    color: '#000',
                  }}
                >
                  {file.imgB64Name}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    margin: 0,
                    fontSize: {
                      xs: '12px!important',
                      md: '14px!important',
                    },
                    fontWeight: 400,
                    color: '#959595',
                  }}
                >
                  {calc_image_size(file.imgB64)} KB
                </Typography>
              </Box>
              {/* {onClick !== undefined && (
                <IconButton onClick={() => onClick(i)}>
                  <img
                    style={{ margin: 'auto' }}
                    src={window.location.origin + '/trash-01.png'}
                    width="20px"
                    height="20px"
                    alt="trash"
                  />
                </IconButton>
              )} */}
            </FileName>
          </Box>
        </Link>
      ) : (
        <Box key={`${file.imgB64Name}-${i}`} mt={1.6}>
          <FileName>
            <Box>
              <img
                style={{ margin: 'auto' }}
                src={window.location.origin + '/file_icon.png'}
                width="32px"
                height="32px"
                alt="file_icon"
              />
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
              <Typography
                variant="h4"
                noWrap
                sx={{
                  margin: 0,
                  fontSize: {
                    xs: '12px!important',
                    md: '14px!important',
                  },
                  fontWeight: 400,
                  color: '#000',
                }}
              >
                {file.imgB64Name}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  margin: 0,
                  fontSize: {
                    xs: '12px!important',
                    md: '14px!important',
                  },
                  fontWeight: 400,
                  color: '#959595',
                }}
              >
                {calc_image_size(file.imgB64)} KB
              </Typography>
            </Box>
            {onClick !== undefined && (
              <IconButton onClick={() => onClick(i)}>
                <img
                  style={{ margin: 'auto' }}
                  src={window.location.origin + '/trash-01.png'}
                  width="20px"
                  height="20px"
                  alt="trash"
                />
              </IconButton>
            )}
          </FileName>
        </Box>
      ),
    )
  )
}

export default Filename
