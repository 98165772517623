import * as yup from 'yup'

const schema = yup
  .object({
    seller: yup.string().email('The e-mail format is incorrect').required('This field is required'),
    buyer: yup.string().email('The e-mail format is incorrect').required('This field is required'),
  })
  .required()

export default schema
