import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material'

function ContentTextTitle({ title, sx }) {
  return (
    <Box
      sx={{
        pb: {
          xs: 2,
          md: 3,
        },
        pt: {
          xs: 2,
          md: 3,
        },
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" sx={{ ...sx }}>
        {title}
      </Typography>
    </Box>
  )
}

ContentTextTitle.defaultProps = {
  title: (
    <Fragment>
      Ticketmelon Thailand <br /> Wristband Pre-registration
    </Fragment>
  ),
}

export default ContentTextTitle
