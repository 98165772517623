import React from 'react'
import { Box } from '@mui/material'

function LogoBrand() {
  return (
    <Box sx={{ mx: 5, mt: 5, mb: 0, textAlign: 'center' }}>
      <img src={window.location.origin + '/logo.png'} width="100%" />
    </Box>
  )
}

export default LogoBrand
