import React from 'react'
import moment from 'moment'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
// import StepContent from '@mui/material/StepContent'
import { styled } from '@mui/material/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CircleIcon from '@mui/icons-material/Circle'
import useMediaQuery from '@mui/material/useMediaQuery'

// const steps = [
//   {
//     label: 'You have to confirm payment slip',
//   },
//   {
//     label: 'Create an ad group',
//   },
//   {
//     label: 'Create an ad',
//   },
// ]

const QontoConnector = styled(StepConnector)(({ theme, severity }) => {
  return {
    marginLeft: '10px',
    position: 'relative',
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        ...(severity === 'warning' && {
          borderColor: '#FC7B03',
        }),
        ...(severity === 'error' && {
          borderColor: '#AD1B1B',
        }),
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#00E964',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: '#E1E1E1',
      // borderColor: '#00E964',
      ...(severity === 'success' && {
        borderColor: '#04BA52',
      }),
      // ...(severity === 'warning' && {
      //   borderColor: '#FC9403',
      // }),
      borderTopWidth: 3,
      borderRadius: 1,
      position: 'absolute',
      // left: '137px',
      left: '130px',
      bottom: '-9px',
      minHeight: '16px',
    },
  }
})
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  // color: '#FC9403',
  display: 'flex',
  height: 20,
  alignItems: 'center',

  ...(ownerState.active &&
    {
      // color: '#FC9403',
    }),
  '& .QontoStepIcon-completedIcon': {
    // color: '#E1E1E1',
    color: '#04BA52',
    zIndex: 1,
    fontSize: 20,
  },
  '& .QontoStepIcon-circle-warning': {
    color: '#FC7B03',
    zIndex: 1,
    fontSize: 20,
  },
  '& .QontoStepIcon-circle-success': {
    color: '#04BA52',
    zIndex: 1,
    fontSize: 20,
  },
  '& .QontoStepIcon-circle-error': {
    color: '#AD1B1B',
    zIndex: 1,
    fontSize: 20,
  },
}))

function TimelineStatus(severity = 'warning', steps = []) {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  function QontoStepIcon(props) {
    const { active, className } = props
    // console.log('QontoStepIcon', { props, className })

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {active ? (
          <CircleIcon className={`QontoStepIcon-circle-${severity}`} />
        ) : (
          <CheckCircleOutlineIcon className="QontoStepIcon-completedIcon" />
        )}
      </QontoStepIconRoot>
    )
  }
  let output = null
  const reverse = [...steps].reverse()
  // console.log('steps', { steps, reverse })
  switch (severity) {
    case 'warning':
      output = (
        <Box>
          <Stepper
            activeStep={steps.length - 1}
            orientation="vertical"
            connector={!matches && <QontoConnector severity="warning" />}
            sx={{
              flexDirection: 'column-reverse',
            }}
          >
            {reverse.map((step, index) => (
              <Step key={`${step.label}-${index}`} sx={{ padding: '8px 0px' }}>
                <Grid container alignItems="flex-start" wrap="nowrap">
                  <Grid item xs={4} sm={3}>
                    {reverse.length - 1 === index ? (
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: {
                            xs: '12px!important',
                            md: '14px!important',
                          },
                          minWidth: '120px',
                        }}
                      >
                        Timeline:
                      </Typography>
                    ) : (
                      <Typography
                        align="left"
                        sx={{
                          color: '#04BA52',
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                          },
                          fontWeight: 400,
                          // paddingTop: '4px',
                        }}
                      >
                        {moment(step.UPDATED_AT).format('DD/MM/YY HH:mm')}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <StepLabel
                      sx={{
                        padding: 0,
                        alignItems: 'flex-start',
                        '& .MuiStepLabel-label.Mui-active': {
                          color: '#FC7B03',
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                          },
                          fontWeight: 400,
                        },
                        '& .MuiStepLabel-label.Mui-completed': {
                          color: '#04BA52',
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                          },
                          fontWeight: 400,
                        },
                        '& .MuiStepLabel-label': {
                          color: '#04BA52',
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                          },
                          fontWeight: 400,
                        },
                        '& .MuiStepLabel-iconContainer': {
                          paddingLeft: '8px',
                        },
                      }}
                      StepIconComponent={QontoStepIcon}
                    >
                      <Typography
                        noWrap={!matches}
                        sx={{
                          color: 'inherit',
                          fontSize: 'inherit',
                          fontWeight: 'inherit',
                        }}
                      >
                        {step.label}
                      </Typography>
                      {/* {step.label} */}
                    </StepLabel>
                  </Grid>
                </Grid>
              </Step>
            ))}
          </Stepper>
        </Box>
      )
      break
    case 'success':
      output = (
        <Grid container alignItems="baseline">
          <Grid item xs={12} sm={12}>
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  xs: '12px!important',
                  md: '14px!important',
                },
              }}
            >
              Timeline:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box>
              <Stepper
                activeStep={0}
                orientation="vertical"
                connector={!matches && <QontoConnector severity="success" />}
              >
                {steps.map((step, index) => (
                  <Step key={`${step.label}-${index}`} sx={{ padding: '8px 0px' }}>
                    <Grid container alignItems="flex-start" wrap="nowrap">
                      <Grid item xs={4} sm={3}>
                        {index === 0 ? (
                          <Typography
                            align="left"
                            sx={{
                              color: '#04BA52',
                              // fontSize: '18px',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                            }}
                          >
                            {moment(step.UPDATED_AT).format('DD/MM/YY HH:mm')}
                          </Typography>
                        ) : (
                          <Typography
                            align="left"
                            sx={{
                              color: '#04BA52',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                              // paddingRight: '8px',
                              // paddingTop: '4px',
                              // minWidth: '130px',
                            }}
                          >
                            {moment(step.UPDATED_AT).format('DD/MM/YY HH:mm')}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={8} sm={9}>
                        <StepLabel
                          sx={{
                            padding: 0,
                            alignItems: 'flex-start',
                            '& .MuiStepLabel-label.Mui-active': {
                              color: '#04BA52',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                            },
                            '& .MuiStepLabel-label': {
                              color: '#04BA52',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                            },
                            '& .MuiStepLabel-iconContainer': {
                              paddingLeft: '8px',
                            },
                          }}
                          className="gihuuji"
                          StepIconComponent={QontoStepIcon}
                        >
                          <Typography
                            noWrap={!matches}
                            sx={{
                              color: 'inherit',
                              fontSize: 'inherit',
                              fontWeight: 'inherit',
                            }}
                          >
                            {step.label}
                          </Typography>
                        </StepLabel>
                      </Grid>
                    </Grid>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        </Grid>
      )
      break
    case 'error':
      output = (
        <Grid container alignItems="baseline">
          <Grid item xs={12} sm={12}>
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  xs: '12px!important',
                  md: '14px!important',
                },
              }}
            >
              Timeline:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box>
              <Stepper
                activeStep={steps.length - 1}
                orientation="vertical"
                connector={!matches && <QontoConnector severity="error" />}
                sx={{
                  flexDirection: 'column-reverse',
                }}
              >
                {reverse.map((step, index) => (
                  <Step key={`${step.label}-${index}`} sx={{ padding: '8px 0px' }}>
                    <Grid container alignItems="flex-start" wrap="nowrap">
                      <Grid item xs={4} sm={3}>
                        {reverse.length - 1 === index ? (
                          <Typography
                            align="left"
                            sx={{
                              color: '#AD1B1B',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                              // paddingRight: '8px',
                              paddingTop: '4px',
                              // minWidth: '130px',
                            }}
                          >
                            {moment(step.UPDATED_AT).format('DD/MM/YY HH:mm')}
                          </Typography>
                        ) : (
                          <Typography
                            align="left"
                            sx={{
                              color: '#00E964',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                              // paddingRight: '8px',
                              // paddingTop: '4px',
                              // minWidth: '130px',
                            }}
                          >
                            {moment(step.UPDATED_AT).format('DD/MM/YY HH:mm')}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={8} sm={9}>
                        <StepLabel
                          sx={{
                            padding: 0,
                            alignItems: 'flex-start',
                            '& .MuiStepLabel-label.Mui-active': {
                              color: '#AD1B1B',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                            },
                            '& .MuiStepLabel-label.Mui-completed': {
                              color: '#00E964',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                            },
                            '& .MuiStepLabel-label': {
                              color: '#E1E1E1',
                              fontSize: {
                                xs: '12px',
                                sm: '14px',
                              },
                              fontWeight: 400,
                            },
                            '& .MuiStepLabel-iconContainer': {
                              paddingLeft: '8px',
                            },
                          }}
                          className="gihuuji"
                          StepIconComponent={QontoStepIcon}
                        >
                          <Typography
                            noWrap={!matches}
                            sx={{
                              color: 'inherit',
                              fontSize: 'inherit',
                              fontWeight: 'inherit',
                            }}
                          >
                            {step.label}
                          </Typography>
                        </StepLabel>
                      </Grid>
                    </Grid>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        </Grid>
      )
      break
    default:
      output = (
        <Grid container alignItems="baseline">
          <Grid item xs={12} sm={2}>
            <Typography variant="h5" sx={{ fontSize: '14px!important' }}>
              Timeline:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Box>
              <Stepper
                activeStep={0}
                orientation="vertical"
                connector={!matches && <QontoConnector />}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        align="left"
                        sx={{
                          color: '#E1E1E1',
                          fontSize: {
                            xs: '14px',
                            sm: '14px',
                          },
                          fontWeight: 700,
                          paddingRight: '8px',
                          paddingTop: '4px',
                          // minWidth: '130px',
                        }}
                      >
                        {/* 08/05/21 11:58 */}
                        {moment(step.UPDATED_AT).format('DD/MM/YY HH:mm')}
                      </Typography>

                      <StepLabel
                        sx={{
                          '& .MuiStepLabel-label.Mui-active': {
                            color: '#FC9403',
                            fontSize: {
                              xs: '14px',
                              sm: '14px',
                            },
                            fontWeight: 700,
                          },
                          '& .MuiStepLabel-label': {
                            color: '#E1E1E1',
                            fontSize: {
                              xs: '14px',
                              sm: '14px',
                            },
                            fontWeight: 700,
                          },
                        }}
                        className="gihuuji"
                        StepIconComponent={QontoStepIcon}
                      >
                        {step.label}
                      </StepLabel>
                    </Box>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        </Grid>
      )
      break
  }
  return output
}

function Timeline({ severity, steps }) {
  return <Box mt={0.8}>{TimelineStatus(severity, steps)}</Box>
}

export default Timeline
