/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import timelineStatus from '../../data/timelineStatus.json'
import { getDataTicket } from '../../service/callAPI'
import QRCode from 'react-qr-code'
import { filterActivitylog } from '../../utils/formatedata'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Container, Typography, Paper, Stack, Grid, Button } from '@mui/material'
import Snackbar from '../../components/Snackbar'
import LogoBrand from '../../components/LogoBrand'
import LoadingInformation from '../../components/LoadingInformation'
import ContentTextTitle from '../../components/ContentTextTitle'
import TicketState from '../../components/TicketState'
import Timeline from '../../components/Timeline'
import Filename from '../../components/Filename'

async function image_to_base64(imgUrl) {
  const base64 = await fetch(imgUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise((res) => {
        reader.onloadend = () => {
          res(reader.result)
        }
      })
    })
  return base64
}

function Details({ qrCode }) {
  const [alert, setAlert] = useState({
    open: false,
    status: 'success',
    message: 'This is a success message!',
  })
  const [file, setFile] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [data, setData] = useState({})
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  let steps = []
  if (data.activityLog) {
    if (data.STATUS_TICKET === 'ACCEPT') {
      filterActivitylog(data.activityLog).map((log, i) => {
        if (log['DATA_003'] === 'ACTIVE' && log['DATA_004'] === 'TRANSFER') {
          steps = [
            ...steps,
            {
              label: timelineStatus['BEFORE'][log['DATA_003']],
              UPDATED_AT: log['CREATED_AT'],
            },
          ]
        } else if (log['DATA_003'] === 'TRANSFER' && log['DATA_004'] === 'WAIT_ACCEPT') {
          steps = [
            ...steps,
            {
              label: timelineStatus['BEFORE'][log['DATA_003']],
              UPDATED_AT: log['CREATED_AT'],
            },
          ]
        } else {
          steps = [
            ...steps,
            {
              label: timelineStatus['SELLER'][log['DATA_004']],
              UPDATED_AT: log['CREATED_AT'],
            },
            {
              label: timelineStatus['SELLER'][log['DATA_003']],
              UPDATED_AT: log['CREATED_AT'],
            },
          ]
        }
      })
    }
    if (data.STATUS_TICKET === 'TRANSFER') {
      filterActivitylog(data.activityLog).map((log, i) => {
        if (log['DATA_003'] === 'ACTIVE' && log['DATA_004'] === 'TRANSFER') {
          if (data.activityLog > 1) {
            steps = [
              ...steps,
              {
                label: timelineStatus['SELLER'][log['DATA_004']],
                UPDATED_AT: log['CREATED_AT'],
              },
            ]
          } else {
            steps = [
              ...steps,
              {
                label: timelineStatus['SELLER'][log['DATA_004']],
                UPDATED_AT: log['CREATED_AT'],
              },
              {
                label: timelineStatus['BEFORE'][log['DATA_003']],
                UPDATED_AT: log['CREATED_AT'],
              },
            ]
          }
        } else {
          steps = [
            ...steps,
            {
              label: timelineStatus['SELLER'][log['DATA_004']],
              UPDATED_AT: log['CREATED_AT'],
            },
            {
              label: timelineStatus['SELLER'][log['DATA_003']],
              UPDATED_AT: log['CREATED_AT'],
            },
          ]
        }
      })
    }
  }
  const actions = async () => {
    let arr = []
    await data.dataTicket.SLIP_PATH.map(async (path, i) => {
      const res = await image_to_base64(path)
      if (res) {
        const myArrayUrl = path.split('/')
        const result = { imgB64Name: myArrayUrl[myArrayUrl.length - 1], imgB64: res, slipUrl: path }
        arr = [...arr, result]
        setFile(arr)
      }
    })
  }

  useEffect(() => {
    setIsLoadingData(true)
    getDataTicket({
      ACTION: 'CHECK',
      CODE: qrCode,
      EVENT_ID: '00fb6af2-25e5-483b-99e6-acbc5c113fdd',
    }).then((res) => {
      // console.log('Regisform', res)
      if (res.statusCode === 0) {
        setData(res.data)
        setIsLoadingData(false)
      } else if (res.code === 97) {
        setIsLoadingData(false)
      }
    })
  }, [])
  useEffect(() => {
    if (data.dataTicket && data.dataTicket.SLIP_PATH.length > 0) {
      actions()
    }
  }, [data])
  // console.log('errors', data)

  return (
    <Container maxWidth="sm" sx={{ pb: 7 }}>
      <LogoBrand />
      <ContentTextTitle
        title="Welcome to Wonder Market, the official platform for selling or transferring a Wonderfruit 2023 ticket"
        sx={{
          color: 'text.primary',
          fontSize: {
            xs: '22px!important',
            md: '24px!important',
          },
          fontFamily: 'Emilio',
          fontWeight: 400,
          width: {
            xs: '324px',
            md: '508px',
          },
          margin: 'auto',
        }}
      />
      {isLoadingData ? (
        <LoadingInformation />
      ) : (
        <Paper
          elevation={0}
          customBorder
          sx={{
            p: 2.4,
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  xs: '20px!important',
                  md: '22px!important',
                },
                fontWeight: 700,
                color: '#000000',
                margin: 'auto',
                mb: 0.8,
                width: {
                  xs: '212px',
                  md: '100%',
                },
              }}
            >
              {data?.STATUS_TICKET === 'ACCEPT'
                ? 'The ticket transfer was successful'
                : 'The ticket is being processed'}
            </Typography>
            {/* {data?.STATUS_TICKET !== 'ACCEPT' && (
              <Typography
                variant="h5"
                sx={{ fontSize: '1.125rem!important', fontWeight: 400, color: '#000000' }}
              >
                Please wait while the buyer completes the transaction
              </Typography>
            )} */}
          </Box>

          <Typography
            variant="h5"
            sx={{
              fontSize: {
                xs: '18px!important',
                md: '20px!important',
              },
              mb: {
                xs: 2,
                md: 3,
              },
              mt: {
                xs: 2,
                md: 3,
              },
            }}
          >
            Ticket Details:
          </Typography>
          <Box>
            <Stack direction={matches ? 'row' : 'column'} spacing={1.5}>
              <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
                {qrCode && (
                  <QRCode
                    value={qrCode}
                    size={150}
                    style={{ border: '10px solid #FFF', borderRadius: '6px' }}
                  />
                )}
              </Box>
              <Box sx={{ width: matches ? 'calc(100% - 150px)' : '100%', py: 0 }}>
                <Grid container alignItems="baseline" wrap="nowrap" sx={{ mb: 1 }}>
                  <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 5 : 4}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: {
                          xs: '12px!important',
                          md: '14px!important',
                        },
                        fontWeight: 400,
                      }}
                    >
                      Entrant Code:
                    </Typography>
                  </Grid>
                  <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 7 : 8}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          xs: '14px!important',
                          md: '16px!important',
                        },
                        fontWeight: 400,
                      }}
                    >
                      {data?.dataTicket?.code}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="baseline" wrap="nowrap" sx={{ mb: 1 }}>
                  <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 5 : 4}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: {
                          xs: '12px!important',
                          md: '14px!important',
                        },
                        fontWeight: 400,
                      }}
                    >
                      Ticket Type:
                    </Typography>
                  </Grid>
                  <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 7 : 8}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          xs: '14px!important',
                          md: '16px!important',
                        },
                        fontWeight: 400,
                      }}
                    >
                      {data?.ticketTypeDetail?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="baseline" wrap="nowrap" sx={{ mb: 1 }}>
                  <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 5 : 4}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: {
                          xs: '12px!important',
                          md: '14px!important',
                        },
                        fontWeight: 400,
                      }}
                    >
                      Seller’s E-mail:
                    </Typography>
                  </Grid>
                  <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 7 : 8}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          xs: '14px!important',
                          md: '16px!important',
                        },
                        fontWeight: 400,
                      }}
                    >
                      {data?.dataTicket?.EMAIL}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="baseline" wrap="nowrap" sx={{ mb: 1 }}>
                  <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 5 : 4}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: {
                          xs: '12px!important',
                          md: '14px!important',
                        },
                        fontWeight: 400,
                      }}
                    >
                      Buyer’s E-mail:
                    </Typography>
                  </Grid>
                  <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 7 : 8}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          xs: '14px!important',
                          md: '16px!important',
                        },
                        fontWeight: 400,
                      }}
                    >
                      {data?.dataTicket?.TRANSFER_EMAIL}
                    </Typography>
                  </Grid>
                </Grid>
                {data?.STATUS_TICKET !== 'ACCEPT' && (
                  <Grid container alignItems="baseline" wrap="nowrap" sx={{ mb: 1 }}>
                    <Grid item xs={5}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: {
                            xs: '12px!important',
                            md: '14px!important',
                          },
                          fontWeight: 400,
                        }}
                      >
                        Payment Session Time out:
                      </Typography>
                    </Grid>
                    <Grid item xs={data?.STATUS_TICKET !== 'ACCEPT' ? 7 : 8}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: {
                            xs: '14px!important',
                            md: '16px!important',
                          },
                          fontWeight: 400,
                          color: '#AD1B1B',
                        }}
                      >
                        {moment(data?.dataTicket?.TRANSFER_TTL).format('HH:mm')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              mb: {
                xs: 2,
                md: 3,
              },
              mt: {
                xs: 2,
                md: 3,
              },
            }}
          >
            <TicketState status={data.STATUS_TICKET} />
            {data?.STATUS_TICKET === 'ACCEPT' ? (
              <Timeline severity="success" steps={steps} />
            ) : (
              <Timeline severity="warning" steps={steps} />
            )}
          </Box>

          {data?.STATUS_TICKET === 'ACCEPT' && (
            <Box
              sx={{
                mb: {
                  xs: 2,
                  md: 3,
                },
              }}
            >
              <Filename files={file} onClick={undefined} />
            </Box>
          )}

          <Box>
            {data?.STATUS_TICKET === 'ACCEPT' ? (
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    xs: '12px!important',
                    md: '14px!important',
                  },
                  fontWeight: 400,
                }}
              >
                {/* You’ve reached the end of the transfer process. For any further questions, contact
                support. */}
                You’ve reached the end of the transfer process. For any further questions, contact
                support: <span style={{ color: '#0000ee' }}>info@wonderfruit.co</span>
              </Typography>
            ) : (
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    xs: '12px!important',
                    md: '14px!important',
                  },
                  fontWeight: 400,
                }}
              >
                Does it seem like there's a problem? Here's what you can do:
              </Typography>
            )}
          </Box>
          <Box>
            {data?.STATUS_TICKET !== 'ACCEPT' ? (
              <Fragment>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    fontSize: '14px!important',
                    fontWeight: 400,
                    textIndent: '-21px',
                    pl: '21px',
                  }}
                >
                  <span>1.</span> Get in touch with the buyer to double-check their e-mail address
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    fontSize: '14px!important',
                    fontWeight: 400,
                    textIndent: '-21px',
                    pl: '21px',
                  }}
                >
                  2. Wait for the session to time out and start a new transaction
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    fontSize: '14px!important',
                    fontWeight: 400,
                    textIndent: '-21px',
                    pl: '21px',
                  }}
                >
                  3. Contact support for assistance:{' '}
                  <span style={{ color: '#0000ee' }}>info@wonderfruit.co</span>
                </Typography>
              </Fragment>
            ) : (
              <Typography
                variant="body2"
                sx={{ mt: 1, fontSize: '14px!important', fontWeight: 400 }}
              >
                {/* E-mail support: <span style={{ color: '#0000ee' }}>info@wonderfruit.co</span> */}
              </Typography>
            )}
          </Box>
          <Box sx={{ mt: 2.4, mb: 2.4, textAlign: 'center' }}>
            <Button
              onClick={() => (window.location.href = `/`)}
              variant="contained"
              color="secondary"
              sx={{ mt: { xs: 1, sm: 0 }, width: { xs: '100%', sm: '190px' } }}
            >
              Back to home
            </Button>
          </Box>
        </Paper>
      )}
      <Snackbar
        isOpen={alert.open}
        onClose={setAlert}
        severity={alert.status}
        message={alert.message}
      />
    </Container>
  )
}

export default Details
