/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Snackbar from '../Snackbar'

const Container = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px 24px',
  borderWidth: '1px',
  borderRadius: '12px',
  borderColor: '#EAECF0',
  borderStyle: 'solid',
  backgroundColor: '#ffffff',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}))

// const fileToBase64 = (filename, filepath) => {
//   return new Promise((resolve) => {
//     var file = new File([filename], filepath)
//     var reader = new FileReader()
//     // Read file content on file loaded event
//     reader.onload = function (event) {
//       resolve(event.target.result)
//     }

//     // Convert data to base64
//     reader.readAsDataURL(file)
//   })
// }
async function image_to_base64(file) {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader()
    fileReader.onload = (e) => resolve(fileReader.result)
    fileReader.onerror = (error) => {
      console.log(error)
      alert('An Error occurred please try again, File might be corrupt')
    }
    fileReader.readAsDataURL(file)
  })
  return result_base64
}

function DropzoneComponent({ onChange, fileCount }) {
  const [alert, setAlert] = useState({
    open: false,
    status: 'success',
    message: 'This is a success message!',
  })
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
    noDrag: true,
    noClick: fileCount >= 5,
    accept: {
      'image/*': ['.jpeg', '.png', '.pdf'],
    },
    maxSize: 1000000,
  })

  // fileRejections.map(({ file, errors }) => {
  //   console.log('first', { file, errors })
  // })
  // console.log('acceptedFiles', acceptedFiles)
  async function process_image(file) {
    const res = await image_to_base64(file)
    // console.log('res=> ', res)
    if (res) {
      onChange({ imgB64Name: file.name, imgB64: res })
    } else {
      console.log('return err')
      return null
    }
  }
  useEffect(() => {
    console.log('useEffect')
    if (acceptedFiles.length > 0) {
      process_image(acceptedFiles[0])
    }
  }, [acceptedFiles])

  useEffect(() => {
    // console.log('fileRejections', fileRejections)
    if (fileRejections.length > 0) {
      setAlert({
        open: true,
        status: 'error',
        message: 'The file is too large. Please upload it again.',
      })
    }
  }, [fileRejections])

  return (
    <div>
      <Container {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Box sx={{ textAlign: 'center' }}>
          <Box>
            <img
              style={{ margin: 'auto' }}
              src={window.location.origin + '/upload_icon.png'}
              width="40px"
              height="40px"
              alt="upload"
            />
          </Box>
        </Box>
        <p
          style={{
            margin: 0,
            fontSize: '14px',
            color: '#4F4F4F',
            fontWeight: 400,
            lineHeight: '20px',
          }}
        >
          Click to upload or drag and drop
        </p>
        <p
          style={{
            margin: 0,
            fontSize: '12px',
            color: '#4F4F4F',
            fontWeight: 400,
            lineHeight: '14px',
          }}
        >
          PNG, JPG or PDF (Maximum 5 files)
        </p>
      </Container>
      <Snackbar
        isOpen={alert.open}
        onClose={setAlert}
        severity={alert.status}
        message={alert.message}
      />
    </div>
  )
}

export default DropzoneComponent
