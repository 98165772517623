import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#F0ECE5',
    },
    secondary: {
      main: '#000000',
    },
    error: {
      main: '#AD1B1B',
    },
    text: {
      primary: '#000000',
      secondary: '#fff',
    },
    borderPaper: {
      border: '1px solid rgba(0, 0, 0, 0.50)',
    },
  },
  typography: {
    fontFamily: [
      'Acumin Pro',
      'Emilio',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h3: {
      fontSize: '1.38rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      letterSpacing: 'normal',
    },
    subtitle1: {
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 'bold',
    },
    body2: {
      letterSpacing: '0.2px',
    },
  },
  spacing: 10,
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            ...(ownerState.variant === 'h3' && {
              [theme.breakpoints.up('sm')]: {
                fontSize: '1.95rem',
              },
            }),
            ...(ownerState.variant === 'h6' && {
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.95rem',
              },
            }),
            ...(ownerState.variant === 'body2' && {
              color: theme.palette.text.primary,
            }),
          }
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            ...(ownerState.customBorder && {
              // backgroundColor: 'transparent',
              border: theme.palette.borderPaper.border,
              borderRadius: '20px',
              background: '#fff',
              backdropFilter: 'blur(39.5px)',
              position: 'relative',
            }),
          }
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontSize: '0.875rem',
          '&:before': {
            borderBottom: `1px solid ${theme.palette.primary.main} !important`,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontSize: '0.875rem',
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontWeight: 700,
          borderRadius: '30px',
          boxShadow: 'none',
          color: theme.palette.text.primary,
          textTransform: 'inherit',
          ...(ownerState.variant === 'outlined' && {
            border: '1px solid #000000',
          }),
          ...(ownerState.color === 'secondary' && {
            color: theme.palette.text.secondary,
          }),
          '&:hover': {
            boxShadow: 'none',
          },
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // backgroundImage: 'linear-gradient(0deg, #fd02fd, #11002b)',
          color: theme.palette.primary.main,
          backgroundColor: '#0a090e',
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.palette.primary.main,
          fontWeight: '600',
          fontSize: '32px',
          lineHeight: '32px',
          // marginBottom: '24px',
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.palette.text.primary,
          // backgroundColor: '#0a090e',
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ ownerState, theme }) => {
          return {
            color: ownerState.checked ? theme.palette.primary.main : theme.palette.text.primary,
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '32px',
          }
        },
      },
    },
  },
})

export default theme
