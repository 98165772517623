import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import ticketStatus from '../../data/ticketStatus.json'

const Root = styled('div')(({ theme }) => ({
  '& .status': {
    color: '#FC7B03',
  },
  '& .Available': {
    color: '#04BA52',
  },
  '& .Registered': {
    color: '#04BA52',
  },
  '& .Unavailable': {
    color: '#959595',
  },
}))

function TicketState({ status }) {
  return (
    <Root>
      <Box
        sx={{
          mt: {
            xs: 2,
            md: 3,
          },
        }}
      >
        <Grid container>
          <Grid item xs={4} sm={3}>
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  xs: '12px!important',
                  md: '14px!important',
                },
              }}
            >
              Ticket Status:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography
              variant="h4"
              className={`status ${ticketStatus[status]}`}
              sx={{
                fontSize: {
                  xs: '12px!important',
                  md: '14px!important',
                },
                // marginLeft: '8px',
                fontWeight: 400,
              }}
            >
              {status && ticketStatus[status]}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Root>
  )
}

export default TicketState
